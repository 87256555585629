* {
  box-sizing: border-box;
}

.editor-wrapper {
  width: 100%;
  background: #fff;
  padding: 8px;
  border-radius: 6px;
}

.editor-container {
  width: 100%;
  min-height: calc(50vh);
  border: 1px solid var(--primary);
  border-radius: 6px;
  padding: 1rem;
}

.toolbar-grid {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 1fr 1fr;
  padding-bottom: 8px;
}

button {
  margin-right: 8px;
  font-size: 1rem;
  padding: 10px;
  border-radius: 6px;
  border: none;
  background: #fff;
  cursor: pointer;

  &:hover {
    background-color: #0002;
  }
}

.superFancyBlockquote {
  color: #999;
  background: #fff;
  font-family: "Hoefler Text", Georgia, serif;
  font-style: italic;
  border-left: 2px solid #999;
  padding-left: 10px;
}

.codeBlock {
  font-family: fira-code, monospace;
  font-size: inherit;
  background: #ffeff0;
  font-style: italic;
  word-wrap: normal;
  word-wrap: break-word;
  box-decoration-break: slice;
  padding: 0.3rem 2rem;
  border-radius: 0.2rem;
}

.leftAlign {
  text-align: left;
}
.rightAlign {
  text-align: right;
}
.centerAlign {
  text-align: center;
}
.justifyAlign {
  text-align: justify;
}
