@font-face {
  font-family: 'Now';
  font-style: normal;
  font-weight: 100;
  src: url('./theme/fonts/Now-Thin.otf');
}
@font-face {
  font-family: 'Now';
  font-style: normal;
  font-weight: 300;
  src: url('./theme/fonts/Now-Light.otf');
}
@font-face {
  font-family: 'Now';
  font-style: normal;
  font-weight: 400;
  src: url('./theme/fonts/Now-Regular.otf');
}
@font-face {
  font-family: 'Now';
  font-style: normal;
  font-weight: 500;
  src: url('./theme/fonts/Now-Medium.otf');
}
@font-face {
  font-family: 'Now';
  font-style: normal;
  font-weight: 700;
  src: url('./theme/fonts/Now-Bold.otf');
}
@font-face {
  font-family: 'Now';
  font-style: normal;
  font-weight: 900;
  src: url('./theme/fonts/Now-Black.otf');
}

body {
  --primary: #FFCC29;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast {
  box-shadow: 0px 0px 10px 3px rgba(255, 204, 41, 0.25);
}

.styled-scroll-transparent {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
  }
  &::-webkit-scrollbar-thumb {
    background: #FFCC29;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #FFEE87;
  }
}

.styled-scroll-transparent-desktop {
  @media (min-width: 901px) {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: #FFCC29;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #FFEE87;
    }
  }
}

.MuiFormHelperText-root {
  color: #FFDEDE !important
}

.hidePopoverBackdrop {
  z-index: 0 !important;
}

.MuiInputBase-root.Mui-error  {
  background-color: #FFDEDE;
}